import { ValidationRegex } from '@/common/utils/ValidationRegex';

import { FieldValidator } from './Validator';

export const emailValidator = (message?: string): FieldValidator => {
  return (value: string) => {
    if (!value) return null;
    const isEmail = ValidationRegex.email.test(String(value).toLowerCase());
    return !isEmail ? message || 'Not a valid email' : null;
  };
};
