import { datadogRum } from '@datadog/browser-rum';

import { Guid } from '@/common/models/Guid';
import { SiteUser, UserAttributesHelper } from '@/common/models/SiteUser';

import { SiteCache } from '../../front/utils/SiteCache';

type UserTypes = 'User' | 'Administrator';

const sessionV2IdKey = 'sessionV2Id';

interface SetAnonymousUserData {
  id: string;
  siteDomain: string;
}

interface SetKnownUserData {
  id: string;
  name: string;
  email: string;
  isAnonymous: string;
  [key: string]: any;
}

interface SetAdministatorData {
  id: string;
  adminType: 'Standard' | 'SuperUser';
  name: string;
  email: string;
  [key: string]: string;
}

type UserDataTypes = (
  | SetAdministatorData
  | SetAnonymousUserData
  | SetKnownUserData
) & {
  type: UserTypes;
};

export class DataDogService {
  private static shouldTrack() {
    return window && window.location.hostname !== 'localhost';
  }

  private static setDataDogUser(data: UserDataTypes): void {
    try {
      datadogRum.setUser(data as any);
    } catch (err) {
      console.error('Failed to set DataDog user', err);
    }
  }

  private static setDataDogUserProperty(key: string, value: string): void {
    try {
      datadogRum.setUserProperty(key, value);
    } catch (err) {
      console.error('Failed to set DataDog user property', err);
    }
  }

  private static forgetDataDogUser(): void {
    datadogRum.clearUser();
  }

  private static tryGetSiteDomain() {
    return window?.location.hostname ?? '';
  }

  static setAdministrator(data: SetAdministatorData): void {
    if (DataDogService.shouldTrack()) {
      const allData: UserDataTypes = { ...data, type: 'Administrator' };
      DataDogService.setDataDogUser(allData);
    }
  }

  static forgetAdministor(): void {
    if (DataDogService.shouldTrack()) {
      DataDogService.forgetDataDogUser();
    }
  }

  static setAnonymousUser(data: SetAnonymousUserData): void {
    if (DataDogService.shouldTrack()) {
      const allData: UserDataTypes = {
        ...data,
        type: 'User',
        siteDomain: DataDogService.tryGetSiteDomain()
      };
      DataDogService.setDataDogUser(allData);
    }
  }

  static setKnownUser(data: SetKnownUserData): void {
    if (DataDogService.shouldTrack()) {
      const allData: UserDataTypes = {
        ...data,
        type: 'User',
        siteDomain: DataDogService.tryGetSiteDomain()
      };
      DataDogService.setDataDogUser(allData);
    }
  }

  static setUserProperty(key: string, value: string): void {
    if (DataDogService.shouldTrack()) {
      DataDogService.setDataDogUserProperty(key, value);
    }
  }

  static forgetUser(): void {
    if (DataDogService.shouldTrack()) {
      DataDogService.forgetDataDogUser();
    }
  }
}

const removedAttributes = ['first_name', 'last_name'];
const sanitizeObjectWithoutAttributes = (o, attributesToExclude: string[]) =>
  Object.keys(o)
    .filter((key) => !attributesToExclude.some((a) => a === key.toLowerCase()))
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: String(o[key])
      };
    }, {});

export const DataDogServiceHelper = {
  /**
   * Set user information in data dog, including any submission fields.
   */
  trySetUserInformation: (user: SiteUser, fields?: Record<string, any>) => {
    if (!user) {
      return;
    }

    fields = fields || {};
    try {
      DataDogService.setKnownUser({
        id: SiteCache.getState().userId?.toString(),
        ...sanitizeObjectWithoutAttributes(fields, removedAttributes),
        name: user.name || UserAttributesHelper.tryGetName(fields),
        email: user.email || UserAttributesHelper.tryGetEmail(fields),
        isAnonymous: String(user.isAnonymous)
      });
    } catch (e) {
      console.error('Failed to set datadog information', e);
    }
  },
  trySetSessionV2Information: (sessionId: Guid) => {
    try {
      DataDogService.setUserProperty(
        sessionV2IdKey,
        sessionId?.toString() || ''
      );
    } catch (e) {
      console.error('Failed to set datadog information', e);
    }
  }
};
