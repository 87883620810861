import {
  Input as MantineInput,
  InputLabelProps as MantineInputLabelProps
} from '@mantine/core';
import { forwardRef, ReactNode, useMemo } from 'react';

import { Box } from '@/common/components/Display/Box';
import { Group, GroupProps } from '@/common/components/Display/Group';
import { InfoDecorator } from '@/common/components/InfoDecorator';
import { OptionalSkeletonV2 } from '@/common/components/Skeleton';
import { TooltipProps } from '@/common/components/Tooltip';

export interface InputRendererLabelProps extends MantineInputLabelProps {
  /** Label content */
  children: ReactNode;
  showSkeleton?: boolean;
  component?: React.ElementType;
  tooltip?: Omit<TooltipProps, 'children'>;
  rightSection?: ReactNode;
  containerAlignment?: GroupProps['align'];
}

export const InputRendererLabel = forwardRef<
  HTMLLabelElement,
  InputRendererLabelProps
>(({ rightSection, containerAlignment, mb = 3, ...rest }, ref) => {
  return !rightSection ? (
    <_Label ref={ref} mb={mb} {...rest} />
  ) : (
    <Group
      justify="space-between"
      align={containerAlignment}
      mb={mb}
      gap="0.15rem"
    >
      <_Label {...rest} ref={ref} />
      {rightSection}
    </Group>
  );
});

const _Label = forwardRef<
  HTMLLabelElement,
  Omit<InputRendererLabelProps, 'rightSection'>
>(
  (
    {
      tooltip,
      children,
      showSkeleton,
      id,
      size,
      htmlFor: htmlForProp,
      color,
      ...rest
    },
    ref
  ) => {
    const htmlFor = useMemo(() => {
      if (htmlForProp) return htmlForProp;
      return id?.replace?.('-label', '');
    }, [id, htmlForProp]);

    return (
      <MantineInput.Label
        ref={ref}
        htmlFor={htmlFor}
        size={size}
        c={color}
        {...rest}
      >
        <OptionalSkeletonV2 visible={showSkeleton}>
          <InfoDecorator {...tooltip}>
            <Box display="inline">{children}</Box>
          </InfoDecorator>
        </OptionalSkeletonV2>
      </MantineInput.Label>
    );
  }
);
