import isNil from 'lodash/isNil';
import { ReactElement, useEffect } from 'react';
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldError,
  FieldPath,
  FieldPathValue,
  FieldValues,
  SetValueConfig,
  useFormContext,
  UseFormResetField,
  UseFormSetValue,
  UseFormStateReturn,
  UseFormTrigger
} from 'react-hook-form';

import { useDeepCompareEffect } from '@/common/hooks/useDeepCompareEffects';
import { KeyOf } from '@/common/models/KeyOf';

import {
  FieldValidator,
  FieldValidators,
  RequiredValidatorOptions,
  Validator
} from '../FormValidators';

export interface IAmFormHookField<TFieldValues extends FieldValues = any> {
  hook: FormHookFieldProps<TFieldValues>;
}
export interface FormHookFieldProps<
  TFieldValues extends FieldValues = FieldValues
> {
  field: ControllerRenderProps<TFieldValues, any>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<TFieldValues>;
  trigger: UseFormTrigger<TFieldValues>;
  setValue: UseFormSetValue<TFieldValues>;
  setFieldValue: <TFieldPath extends FieldPath<TFieldValues>>(
    value: FieldPathValue<TFieldValues, TFieldPath>,
    options?: SetValueConfig
  ) => void;
  resetField: UseFormResetField<TFieldValues>;
  errorMessage: string;
  showError: boolean;
  required?: boolean;
  disabled?: boolean;
}
export interface FormHookFieldOuterProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext = unknown
> {
  name: KeyOf<TFieldValues>;
  children: (props: FormHookFieldProps<TFieldValues>) => ReactElement;
  validators?: FieldValidator<TFieldValues, TContext>[];
  required?: RequiredValidatorOptions;
  overrideShowRequiredAsteriskIcon?: boolean;
  contextData?: TContext;
  validateOnLoad?: boolean;
  disabled?: boolean;
}

const empty = {};

export function FormHookField<
  TFieldValues extends FieldValues = FieldValues,
  TContext = unknown
>({
  name,
  validateOnLoad,
  required,
  validators,
  contextData: contextDataProp,
  disabled,
  overrideShowRequiredAsteriskIcon,
  children
}: FormHookFieldOuterProps<TFieldValues, TContext>) {
  const { control, getValues, trigger } = useFormContext<TFieldValues>();

  const validator = new Validator<TFieldValues>(validators);

  const contextData = contextDataProp ?? empty;
  useDeepCompareEffect(() => {
    trigger(name);
  }, [name, contextData]);

  return (
    <Controller<TFieldValues, any>
      name={name}
      control={control}
      rules={{
        validate: {
          required: (v) =>
            new Validator<TFieldValues>([
              FieldValidators.required(required)
            ]).validate(v, getValues(), contextData),
          rest: (v) => validator.validate(v, getValues(), contextData)
        }
      }}
      render={({ field, fieldState, formState }) => (
        <_ChildWrapper
          field={field}
          fieldState={fieldState}
          formState={formState}
          disabled={disabled}
          overrideShowRequiredAsteriskIcon={overrideShowRequiredAsteriskIcon}
          validateOnLoad={validateOnLoad}
          required={required}
        >
          {children}
        </_ChildWrapper>
      )}
    />
  );
}

interface ChildWrapperProps<
  TFieldValues extends FieldValues = FieldValues,
  TContextData = unknown
> extends Pick<
    FormHookFieldOuterProps<TFieldValues, TContextData>,
    | 'children'
    | 'disabled'
    | 'overrideShowRequiredAsteriskIcon'
    | 'validateOnLoad'
    | 'required'
  > {
  field: ControllerRenderProps<TFieldValues>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<TFieldValues>;
  children: FormHookFieldOuterProps<TFieldValues, TContextData>['children'];
}

function _ChildWrapper<TFieldValues extends FieldValues = FieldValues>({
  field,
  fieldState,
  formState,
  disabled,
  overrideShowRequiredAsteriskIcon,
  validateOnLoad,
  required,
  children
}: ChildWrapperProps<TFieldValues>) {
  const { setError, trigger, setValue, resetField } =
    useFormContext<TFieldValues>();

  const { value, name } = field;
  const { error, isTouched } = fieldState;
  const { submitCount, errors } = formState;
  const temporaryError = errors?.root?.[name]?.message;

  //The root object on error is used for temporary errors.
  //All root errors are cleared on every submit
  //We want the root errors to also be cleared when the value changes
  useEffect(() => {
    if (temporaryError) {
      setError(`root.${name}`, { type: 'temp', message: null });
      trigger(name);
    }
  }, [value, name]);

  const errorMessage = resolveErrorMessage(
    error,
    errors?.root?.[name]?.message
  );

  return children({
    formState,
    field,
    fieldState,
    disabled: disabled || formState.isSubmitting,
    trigger,
    setValue,
    setFieldValue: (v, c) => setValue(name, v as any, c),
    resetField,
    errorMessage,
    required: !isNil(overrideShowRequiredAsteriskIcon)
      ? overrideShowRequiredAsteriskIcon
      : required?.enabled === true,
    showError:
      !!errorMessage && (validateOnLoad || isTouched || submitCount > 0)
  });
}
const resolveErrorMessage = (
  fieldError?: FieldError,
  temporaryError?: string
) => {
  if (fieldError) {
    if (fieldError.message) return fieldError.message;
    if (fieldError.type === 'required') {
      return 'This field is required';
    }
    return 'invalid field';
  }

  return temporaryError;
};
