import { get, isNil } from 'lodash';
import { FieldValues } from 'react-hook-form';

import { KeyOf } from '@/common/models/KeyOf';

import { FieldValidator } from './Validator';

type Equals = (value: unknown, matchField: unknown) => boolean;

interface Options<TFieldValues extends FieldValues = FieldValues> {
  matchField: KeyOf<TFieldValues>;
  equals?: Equals;
  message: string;
}

export function matchFieldValidator<
  TFieldValues extends FieldValues = FieldValues
>(options: Options<TFieldValues>): FieldValidator<TFieldValues> {
  return (value, values) => {
    if (isNil(value)) return null;
    const equals: Equals = options?.equals || ((a, b) => a === b);
    const isValid = equals(value, get(values, options.matchField));
    return !isValid ? options.message : null;
  };
}
