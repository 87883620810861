import { FC, ReactNode } from 'react';

import { Tooltip, TooltipProps } from './Tooltip';

export interface OptionalTooltipProps extends Omit<TooltipProps, 'label'> {
  label?: ReactNode;
}

export const OptionalTooltip: FC<OptionalTooltipProps> = ({
  children,
  label,
  ...rest
}) => {
  return (
    <>
      {!label ? (
        children
      ) : (
        <Tooltip label={label} {...rest}>
          {children}
        </Tooltip>
      )}
    </>
  );
};
