import {
  __InputWrapperProps as MantineInputWrapperBaseProps,
  MantineSize
} from '@mantine/core';
import {
  FocusEvent,
  ForwardedRef,
  forwardRef,
  MutableRefObject,
  ReactElement,
  ReactNode,
  useCallback,
  useRef
} from 'react';

import { useConstantCallback } from '@/common/hooks/useConstant';
import { useForkRef } from '@/common/hooks/useForkRef';

import { OptionalSkeletonV2 } from '../../Skeleton';
import {
  InputRendererDescription,
  InputRendererDescriptionProps
} from './_Description';
import { InputRendererLabel, InputRendererLabelProps } from './_Label';

interface WrapperBaseProps {
  error?: ReactNode | boolean;
  inputContainer?: MantineInputWrapperBaseProps['inputContainer'];
}

export interface IAmInputRenderProps<TElement extends HTMLElement>
  extends WrapperBaseProps {
  hideErrorMessage?: boolean;
  selectAllTextOnFocus?: boolean;
  autoFocus?: boolean;
  size?: MantineSize;
  showSkeleton?: boolean;
  onFocus?: (e: FocusEvent<TElement>) => void;
  labelProps?: Omit<
    InputRendererLabelProps,
    'showSkeleton' | 'labelElement' | 'children'
  >;
  descriptionProps?: Omit<
    InputRendererDescriptionProps,
    'showSkeleton' | 'children'
  >;
}

interface ChildrenProps<TElement extends HTMLElement> extends WrapperBaseProps {
  onFocus: (e: React.FocusEvent<TElement>) => void;
  labelProps: Omit<InputRendererLabelProps, 'children'>;
  size?: MantineSize;
  descriptionProps: Omit<InputRendererDescriptionProps, 'children'>;
}
export interface InputRendererProps<TElement extends HTMLElement>
  extends IAmInputRenderProps<TElement> {
  children: (
    props: ChildrenProps<TElement>,
    ref: MutableRefObject<TElement>
  ) => ReactElement;
}

function _InputRenderer<TElement extends HTMLElement>(
  {
    children,
    autoFocus,
    onFocus,
    selectAllTextOnFocus,
    size,
    error,
    hideErrorMessage,
    showSkeleton,
    inputContainer,
    labelProps = {},
    descriptionProps = {}
  }: InputRendererProps<TElement>,
  forwardedRef?: ForwardedRef<TElement>
) {
  const ref = useInputRef({ autoFocus }, forwardedRef);
  const handleFocus = useCallback<typeof onFocus>(
    (e) => {
      if (selectAllTextOnFocus) {
        (e.target as any)?.select?.();
      }
      onFocus?.(e);
    },
    [selectAllTextOnFocus, onFocus]
  );

  (labelProps as any).labelElement = InputRendererLabel;
  labelProps.size = size;
  (descriptionProps as any).component = InputRendererDescription;
  descriptionProps.size = size;

  return (
    <>
      {children(
        {
          onFocus: handleFocus,
          inputContainer: decoratedInputContainer(inputContainer, showSkeleton),
          labelProps,
          size,
          descriptionProps,
          error: !!error && hideErrorMessage ? true : error
        },
        ref as MutableRefObject<TElement>
      )}
    </>
  );
}

type InputContainer = WrapperBaseProps['inputContainer'];

const decoratedInputContainer = (
  original?: InputContainer,
  showSkeleton?: boolean
): InputContainer => {
  const hasInputContainer = typeof original === 'function';

  return (input) => {
    return (
      <OptionalSkeletonV2 visible={showSkeleton}>
        {hasInputContainer ? original(input) : input}
      </OptionalSkeletonV2>
    );
  };
};

function useInputRef<TElement extends HTMLElement>(
  props: Pick<InputRendererProps<TElement>, 'autoFocus'>,
  forwardedRef?: React.ForwardedRef<TElement>
) {
  const hasFocussed = useRef(false);
  const refCallback = useConstantCallback((element: TElement) => {
    if (props.autoFocus && !hasFocussed.current) {
      hasFocussed.current = true;
      element?.focus?.();
    }
  });

  return useForkRef(forwardedRef, refCallback);
}

export const InputRenderer = forwardRef(_InputRenderer) as <
  TElement extends HTMLElement = HTMLInputElement
>(
  props: InputRendererProps<TElement> & { ref?: ForwardedRef<TElement> }
) => ReturnType<typeof _InputRenderer>;
