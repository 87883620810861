import { FieldValues } from 'react-hook-form';

import { isNil } from '@/common/utils/TypeFunctions';

import { FieldValidator } from './Validator';

type Predicate<TFieldValues, TContext = unknown> = (
  item: unknown,
  arrayValue: unknown,
  values: TFieldValues,
  context: TContext
) => boolean;

interface Options<TFieldValues = unknown, TContext = unknown> {
  isValid: Predicate<TFieldValues, TContext>;
  variant: 'every' | 'some';
  message: string;
}
export function arrayCheckValidator<
  TFieldValues extends FieldValues = FieldValues
>(options: Options<TFieldValues>): FieldValidator<TFieldValues> {
  return (value: unknown[], values, context) => {
    let isValid = !isNil(value) && !!value.length;

    if (isValid) {
      if (options.variant === 'every') {
        isValid = value.every((x) =>
          options.isValid(x, value, values, context)
        );
      }
      if (options.variant === 'some') {
        isValid = value.some((x) => options.isValid(x, value, values, context));
      }
    }

    return !isValid ? options.message : null;
  };
}
