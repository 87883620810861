import {
  Tooltip as MantineTooltip,
  TooltipFloatingProps as MantineTooltipFloatingProps,
  TooltipGroupProps as MantineTooltipGroupProps,
  TooltipProps as MantineTooltipProps
} from '@mantine/core';
import isNil from 'lodash/isNil';
import { FC } from 'react';

/**
 * @see https://mantine.dev/core/tooltip/
 */

export interface TooltipProps
  extends Omit<MantineTooltipProps, 'title' | 'label'> {
  label?: MantineTooltipProps['label'];
  /**
   * @deprecated Use position instead
   */
  placement?: MantineTooltipProps['position'];

  /**
   * @deprecated Use label instead
   */
  title?: MantineTooltipProps['label'];

  /**
   * @deprecated Use disabled instead
   */
  hidden?: boolean;
}
export const TooltipFloating = MantineTooltip.Floating;
export type TooltipFloatingProps = MantineTooltipFloatingProps;
export const TooltipGroup = MantineTooltip.Group;
export type TooltipGroupProps = MantineTooltipGroupProps;

export const Tooltip: FC<TooltipProps> = ({
  children,
  label: labelProp,
  title,
  position: positionProp,
  placement,
  hidden,
  ...rest
}) => {
  if (!children) {
    return <></>;
  }

  const label = title || labelProp;

  if (!label) {
    return <>{children}</>;
  }

  if (isNil(rest.withinPortal)) {
    rest.withinPortal = true;
  }

  if (rest.withinPortal && isNil(rest.zIndex)) {
    //fix for showing tooltip above modals
    rest.zIndex = 1100;
  }

  const position = placement || positionProp;
  return (
    <MantineTooltip
      label={label}
      position={position}
      disabled={hidden}
      {...rest}
    >
      {children}
    </MantineTooltip>
  );
};
