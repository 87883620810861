import {
  Title as MantineTitle,
  TitleProps as MantineTitleProps
} from '@mantine/core';

/**
 * @see https://mantine.dev/core/title/
 */
export const Title = MantineTitle;
export type TitleProps = MantineTitleProps;
