import {
  getThemeColor,
  MantineColor,
  parseThemeColor,
  rem,
  rgba,
  VariantColorResolverResult
} from '@mantine/core';

import { ColorRGBA } from '@/common/models/ColorRGBA';
import { Theme } from '@/theme';

const variants = [
  'filled',
  'filled-danger',
  'filled-success',
  'outline',
  'light',
  'light-danger',
  'light-success',
  'default',
  'subtle',
  'gradient',
  'styled',
  'transparent'
] as const;
export type ButtonVariants = (typeof variants)[number];
export const BaseButtonVariantsArray = variants as unknown as ButtonVariants[];

export type StyledButtonVariantProps = {
  color?: MantineColor;
  bgColor?: MantineColor;
  borderColor?: MantineColor;
};

export interface IMayHaveStyledButtonVariantProps {
  styledVariantProps?: StyledButtonVariantProps;
}

interface ResolveButtonStyledStylesOptions
  extends IMayHaveStyledButtonVariantProps {
  theme: Theme;
}

export const resolveButtonStyledVariantColors = ({
  theme,
  styledVariantProps: styledProps
}: ResolveButtonStyledStylesOptions): VariantColorResolverResult => {
  const styledBgColor = parseThemeColor({
    color: styledProps?.bgColor || theme.other.site.accentBgColor,
    theme
  }).value;

  const bgIsTransparent = styledBgColor === 'transparent';
  const colorRGBABgColor = new ColorRGBA(styledBgColor);
  const bgAlpha = colorRGBABgColor.a ?? 1;
  return {
    background: styledBgColor,
    color: getThemeColor(
      styledProps?.color || theme.other.site.accentColor,
      theme
    ),
    border: `${rem(1)} solid ${getThemeColor(
      styledProps?.borderColor || styledBgColor,
      theme
    )}`,
    hover: bgIsTransparent
      ? 'transparent'
      : bgAlpha < 0.15
        ? rgba(colorRGBABgColor.hex, bgAlpha + 0.05)
        : rgba(colorRGBABgColor.hex, bgAlpha - 0.05)
  };
};
