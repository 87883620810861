import { isFunction } from 'lodash';
import isString from 'lodash/isString';
import { FieldValues } from 'react-hook-form';

import { asBoolean } from '@/common/utils/BooleanFunctions';
import { isNil } from '@/common/utils/TypeFunctions';

import { FieldValidator } from './Validator';

type Predicate<TFieldValues> = (
  value: unknown,
  values: TFieldValues
) => boolean;

export interface RequiredValidatorOptions<TFieldValues = unknown> {
  enabled: boolean | Predicate<TFieldValues>;
  trim?: boolean;
  variant?: 'default' | 'array' | 'boolean';
  message?: string;
}

export function requiredValidator<
  TFieldValues extends FieldValues = FieldValues
>(
  options?: RequiredValidatorOptions<TFieldValues>
): FieldValidator<TFieldValues> {
  return (valueProp, values) => {
    let value = valueProp;

    if (isNil(options?.enabled)) {
      return null;
    }

    if (isFunction(options.enabled)) {
      if (!options.enabled(value, values)) {
        return null;
      }
    } else if (!options.enabled) {
      return null;
    }

    if (options?.trim && isString(value)) {
      value = (value || '').trim();
    }
    const variant = options?.variant || 'default';
    const returnMessage = options?.message || 'Field is required';
    if (isNil(value)) {
      return returnMessage;
    }
    let isValid = false;
    switch (variant) {
      case 'array':
        isValid = !!(value as unknown[]).length;
        break;
      case 'boolean':
        isValid = asBoolean(value as string);
        break;
      case 'default':
        isValid = value !== '';
        break;
    }
    return !isValid ? returnMessage : null;
  };
}
