import {
  createPolymorphicComponent,
  MantineSize,
  Text as MantineText,
  TextProps as MantineTextProps
} from '@mantine/core';
import { forwardRef } from 'react';

export interface TextProps extends Omit<MantineTextProps, 'size'> {
  size?: MantineSize;
}

/**
 * @see https://mantine.dev/core/text/
 */
export const Text = createPolymorphicComponent<'p', TextProps>(
  forwardRef<HTMLParagraphElement, TextProps>(({ color, ...rest }, ref) => {
    return <MantineText c={color} {...rest} ref={ref} />;
  })
);
