import { FieldValues } from 'react-hook-form';

import { isNil } from '@/common/utils/TypeFunctions';

import { FieldValidator } from './Validator';

type Predicate<TFieldValues, TContext = unknown> = (
  item: unknown,
  arrayValue: unknown,
  values: TFieldValues,
  context: TContext
) => boolean;

interface Options<TFieldValues = unknown, TContext = unknown> {
  predicate: Predicate<TFieldValues, TContext>;
  maxCount: number;
  message: string;
}
export function arrayMaxCountValidator<
  TFieldValues extends FieldValues = FieldValues
>(options: Options<TFieldValues>): FieldValidator<TFieldValues> {
  return (value: unknown[], values, context) => {
    if (isNil(value) || !value.length) {
      return null;
    }

    const isValid =
      value.filter((x) => options.predicate(x, value, values, context)).length >
      options.maxCount;

    return !isValid ? options.message : null;
  };
}
