import { arrayCheckValidator } from './_arrayCheck';
import { arrayMaxCountValidator } from './_arrayMaxCount';
import { charactersValidator } from './_characters';
import { emailValidator } from './_email';
import { matchFieldValidator } from './_matchField';
import { numberValidator } from './_number';
import { passwordValidator } from './_password';
import { requiredValidator } from './_required';
import * as rest from './_rest';

export type { RequiredValidatorOptions } from './_required';
export * from './Validator';

export const FieldValidators = {
  arrayCheck: arrayCheckValidator,
  arrayMaxCount: arrayMaxCountValidator,
  characters: charactersValidator,
  email: emailValidator,
  matchField: matchFieldValidator,
  number: numberValidator,
  password: passwordValidator,
  required: requiredValidator,
  ...rest,
  rest: { ...rest }
};
