import { FC } from 'react';

import { SkeletonV2, SkeletonV2Props } from './SkeletonV2';

export const OptionalSkeletonV2: FC<SkeletonV2Props> = ({
  children,
  visible = false,
  ...rest
}) => {
  return (
    <>{!visible ? children : <SkeletonV2 {...rest}>{children}</SkeletonV2>}</>
  );
};
