import {
  Input as MantineInput,
  InputDescriptionProps as MantineInputDescriptionProps
} from '@mantine/core';
import { forwardRef, PropsWithChildren, ReactNode } from 'react';

import { Group, GroupProps } from '@/common/components/Display/Group';
import { InfoDecorator } from '@/common/components/InfoDecorator';
import { OptionalSkeletonV2 } from '@/common/components/Skeleton';
import { TooltipProps } from '@/common/components/Tooltip';

export interface InputRendererDescriptionProps
  extends MantineInputDescriptionProps {
  tooltip?: Omit<TooltipProps, 'children'>;
  showSkeleton?: boolean;
  rightSection?: ReactNode;
  containerAlignment?: GroupProps['align'];
}

export const InputRendererDescription = forwardRef<
  HTMLDivElement,
  PropsWithChildren<InputRendererDescriptionProps>
>(
  (
    { rightSection, containerAlignment = 'baseline', mb = 5, mt, ...rest },
    ref
  ) => {
    return !rightSection ? (
      <_Description ref={ref} mb={mb} mt={mt} {...rest} />
    ) : (
      <Group
        justify="space-between"
        mb={mb}
        mt={mt}
        align={containerAlignment}
        gap="0.15rem"
      >
        <_Description {...rest} ref={ref} />
        {rightSection}
      </Group>
    );
  }
);

const _Description = forwardRef<
  HTMLDivElement,
  Omit<InputRendererDescriptionProps, 'rightSection'>
>(({ tooltip, children, showSkeleton, ...rest }, ref) => {
  return (
    <InfoDecorator {...tooltip}>
      <MantineInput.Description ref={ref} w="fit-content" {...rest}>
        <OptionalSkeletonV2 visible={showSkeleton}>
          {children}
        </OptionalSkeletonV2>
      </MantineInput.Description>
    </InfoDecorator>
  );
});
