import { isNil, isNumber } from '@/common/utils/TypeFunctions';

import { FieldValidator } from './Validator';

export const numberValidator = (message?: string): FieldValidator => {
  return (value) => {
    if (isNil(value)) return null;
    return !isNumber(value) ? message || 'Not a valid number' : null;
  };
};
